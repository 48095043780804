var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderCard", { attrs: { flat: "", type: "spinner--center" } })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _vm.attachmentOptions
              ? _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "text-xs-right": "", "pa-2": "" } },
                      [
                        _c(
                          "MiButton",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              icon: "add",
                              small: "true",
                              color: "secondary"
                            },
                            nativeOn: {
                              click: function($event) {
                                _vm.isCreatingAttachment = true
                                _vm.selectedAttachment = null
                              }
                            }
                          },
                          [_vm._v(" Bijlagen toevoegen ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.attachments && _vm.attachments.length && _vm.attachmentOptions
              ? _c("div", { staticClass: "fixedActionsContainer" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table--fixedActions item__list item__list--table"
                    },
                    [
                      _c(
                        "tr",
                        {
                          staticClass: "label",
                          attrs: { wrap: "", row: "", "align-center": "" }
                        },
                        [
                          _vm._l(_vm.attachmentOptions.headers, function(
                            header,
                            index
                          ) {
                            return [
                              (header.visible !== undefined
                              ? header.visible
                              : true)
                                ? _c(
                                    "td",
                                    {
                                      key:
                                        "header-" + index + "-" + header.value,
                                      class: [header.class ? header.class : ""]
                                    },
                                    [_vm._v(" " + _vm._s(header.text) + " ")]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._l(_vm.attachments, function(attachment) {
                        return _c(
                          "tr",
                          {
                            key: attachment.id,
                            staticClass: "list__item list__item--static"
                          },
                          [
                            _vm._l(_vm.attachmentOptions.headers, function(
                              header,
                              index
                            ) {
                              return [
                                (header.visible !== undefined
                                ? header.visible
                                : true)
                                  ? _c(
                                      "td",
                                      {
                                        key:
                                          "row-" + index + "-" + header.value,
                                        class: [
                                          header.class ? header.class : ""
                                        ]
                                      },
                                      [
                                        header.value === "file_name"
                                          ? [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: attachment.original,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "concatenateString"
                                                        )(attachment.file_name)
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e(),
                                        header.value === "description"
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    attachment[header.value]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          : _vm._e(),
                                        header.value === "show_in_report"
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    attachment.show_in_report ==
                                                      "0"
                                                      ? "nee"
                                                      : attachment.show_in_report ==
                                                        "1"
                                                      ? "ja"
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          : _vm._e(),
                                        header.value === "uploaded_by"
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    attachment[header.value]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          : _vm._e(),
                                        header.value === "created_at"
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("dateFormatTime")(
                                                      attachment.created_at
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          : _vm._e(),
                                        header.value === "actions"
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item__icon item__icon--action"
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          attachment.original,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("cloud_download")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item__icon item__icon--action"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editAttachment(
                                                            attachment
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("edit")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item__icon item__icon--action delete"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteAttachment(
                                                            attachment
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("delete")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      })
                    ],
                    2
                  )
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "v-layout",
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c("span", { staticClass: "fadedText" }, [
                            _vm._v("Er zijn geen bijlagen")
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
          ]
        : _vm._e(),
      _vm.isCreatingAttachment
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isCreatingAttachment,
                callback: function($$v) {
                  _vm.isCreatingAttachment = $$v
                },
                expression: "isCreatingAttachment"
              }
            },
            [
              _c(
                "DefaultDialog",
                { on: { close: _vm.close } },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(
                      "Bijlagen " +
                        _vm._s(
                          _vm.selectedAttachment ? "aanpassen" : "toevoegen"
                        )
                    )
                  ]),
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        _c(
                          "template",
                          { slot: "content" },
                          [
                            _vm.selectedAttachment
                              ? [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "pa-0 ma-0",
                                        attrs: {
                                          label: "Beschrijving",
                                          "hide-details": true
                                        },
                                        model: {
                                          value:
                                            _vm.selectedAttachment.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedAttachment,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedAttachment.description"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            !_vm.selectedAttachment
                              ? _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          "fill-height": "",
                                          "mb-3": "",
                                          "pa-0": "",
                                          wrap: ""
                                        }
                                      },
                                      [
                                        _c("FileUpload", {
                                          ref: "fileUpload",
                                          attrs: {
                                            multiple: true,
                                            height: 300
                                          },
                                          on: { submitKeys: _vm.create }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "template",
                          { slot: "actions" },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: { "justify-end": "", "align-center": "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _vm.selectedAttachment
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fadedText link mr-3",
                                              on: { click: _vm.close }
                                            },
                                            [_vm._v("Annuleren")]
                                          ),
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "primary",
                                                small: "true"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.update($event)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("actions.save"))
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }