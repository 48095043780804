import { Component, Vue, Prop } from 'vue-property-decorator';
import { Project } from '@/models/Project';
import { Media } from '@/models/Report';
import { Options } from '@/components/mi-dialog/MiDialog';
import FileUpload from '@/components/file-upload/FileUpload';
import { Media as MediaModel } from '@/models/Media';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<Attachments>({})
export default class Attachments extends Vue {
  @Prop()
  protected project!: Project;

  protected attachments: Media[] | null = null;

  protected selectedAttachment: Media | null = null;

  protected isCreatingAttachment = false;

  protected isLoading = false;

  // Attachments
  protected get attachmentOptions() {
    if (! this.project) { return null; }
    return {
      headers: [
        {
          text: 'Bijlage',
          value: 'file_name',
        },
        {
          text: 'Beschrijving',
          value: 'description',
        },
        {
          text: 'Geüpload door',
          value: 'uploaded_by',
        },
        {
          text: 'Aangemaakt op',
          value: 'created_at',
        },
        {
          text: 'Acties',
          value: 'actions',
          class: 'actions',
        },
      ],
    };
  }

  protected mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getAttachments();
    // this.dummyMedia();
    this.isLoading = false;
  }

  protected dummyMedia() {
    this.attachments = [
      {
        created_at: '2020-09-01 14:23:23',
        description: '',
        file_name: 'test3.jpg',
        mime_type: 'image/jpeg',
        original: 'https://tcmg-staging.s3.eu-central-1.amazonaws.com/798/test3.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHwaDGV1LWNlbnRyYWwtMSJHMEUCIDpSW1hEDuRq4kyaVJ8pceGLQ%2BwRCDPaYFBsTAh232n1AiEAwjT2t3W6dTo3GzLZh5YKabuworAvKYSqy%2FCFcBghD%2FEqugMIdRABGgw4NzEwMjU2NTkxMDAiDBvtR5ZFvbYMzRvPPiqXA5nSq3TqFyvSU0RPbP%2BNh6INw%2FjCSHrlXnTOvs3eyWdBpBeVQEDJyTvfsXR6kQRKFMrzQOT%2FHYDAfgDMoiGQDsaHt7LUnPKu3BTjQ7gsXNE9CFGfnA5QKUGdNunyQvpkAOrnRglw%2FIQTtzoYfjHlvlMQxU%2FcEz3DpMl2pf1iRtqoHthbuljM%2BExI%2F1UuGgNlcK9hrkkLd%2B5%2Bheorm5wqVCvhRGXZtTpcPgChV45Aijn44qSJZoTlH7dR%2BSnMWvrmFLZ%2FfGhcN%2BPq5FgCX4tyENK78V%2FPe6eLcdklDkUbmqa0X0MkwyHoJcrtPrjZqEPYr2%2B4RRksbRcHZegVbtCt7HPQl3WggV%2F04xMSr12BwsWOFUn0xNhQuIi81S%2B7ZYKXniwk%2BsaPyPMLCeYQ2NaeOAV0dB2v4P5KT1sdjhIdnkhCKqLkjRkxnvkch%2FnZKaZ5xWp4zYtedUszLuC4YAbEkcA9LTki7J%2Fb0iFLCX51g8Yiw%2F0Sl%2B%2FfNCJBvHwWE0M1XlbUmbOMzaBV6UrZe5Mi8eTfgI5YyhhhMMXjuPoFOusBv9lrpKGBBxLetDEGaTBuLaIzkBk2DvfwFtln2eUqFz5StpCsLMMZgZv58uA7GTWdgl6GikYuZ%2Brs2%2BKmnH86hkbo%2FwuxCGMQywHs%2BdLBmT3Zhf4z9hJboruebDJcj9zTUnU6uzBiNx%2F7aYr91t5X4rI1QlOYm%2B3inBigk9dX9w4nleP%2B4NLIQ%2F7afZjDENdZ9smuHcNnlCcHmZRKOJt%2FJrzjA40KtTHDTM6Pbi2YwoNJzqnbq8qYY37z%2B%2Bcpnbt4FKycH7j75m1JG63LTfwqFom0sCNV83UbwKM%2BQRVl0nY97hvnkC7aHIJD%2Fw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA4VTJMZTOPRB7QNXA%2F20200901%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20200901T122324Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Signature=ee83a385c4051ddfa98392a9b5b20940bb0f14dbe475cf4c7c3e085a0ca2ee6f',
        show_in_report: '0',
        sort_order: 594,
        thumb: 'https://tcmg-staging.s3.eu-central-1.amazonaws.com/798/conversions/test3-thumb.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHwaDGV1LWNlbnRyYWwtMSJHMEUCIDpSW1hEDuRq4kyaVJ8pceGLQ%2BwRCDPaYFBsTAh232n1AiEAwjT2t3W6dTo3GzLZh5YKabuworAvKYSqy%2FCFcBghD%2FEqugMIdRABGgw4NzEwMjU2NTkxMDAiDBvtR5ZFvbYMzRvPPiqXA5nSq3TqFyvSU0RPbP%2BNh6INw%2FjCSHrlXnTOvs3eyWdBpBeVQEDJyTvfsXR6kQRKFMrzQOT%2FHYDAfgDMoiGQDsaHt7LUnPKu3BTjQ7gsXNE9CFGfnA5QKUGdNunyQvpkAOrnRglw%2FIQTtzoYfjHlvlMQxU%2FcEz3DpMl2pf1iRtqoHthbuljM%2BExI%2F1UuGgNlcK9hrkkLd%2B5%2Bheorm5wqVCvhRGXZtTpcPgChV45Aijn44qSJZoTlH7dR%2BSnMWvrmFLZ%2FfGhcN%2BPq5FgCX4tyENK78V%2FPe6eLcdklDkUbmqa0X0MkwyHoJcrtPrjZqEPYr2%2B4RRksbRcHZegVbtCt7HPQl3WggV%2F04xMSr12BwsWOFUn0xNhQuIi81S%2B7ZYKXniwk%2BsaPyPMLCeYQ2NaeOAV0dB2v4P5KT1sdjhIdnkhCKqLkjRkxnvkch%2FnZKaZ5xWp4zYtedUszLuC4YAbEkcA9LTki7J%2Fb0iFLCX51g8Yiw%2F0Sl%2B%2FfNCJBvHwWE0M1XlbUmbOMzaBV6UrZe5Mi8eTfgI5YyhhhMMXjuPoFOusBv9lrpKGBBxLetDEGaTBuLaIzkBk2DvfwFtln2eUqFz5StpCsLMMZgZv58uA7GTWdgl6GikYuZ%2Brs2%2BKmnH86hkbo%2FwuxCGMQywHs%2BdLBmT3Zhf4z9hJboruebDJcj9zTUnU6uzBiNx%2F7aYr91t5X4rI1QlOYm%2B3inBigk9dX9w4nleP%2B4NLIQ%2F7afZjDENdZ9smuHcNnlCcHmZRKOJt%2FJrzjA40KtTHDTM6Pbi2YwoNJzqnbq8qYY37z%2B%2Bcpnbt4FKycH7j75m1JG63LTfwqFom0sCNV83UbwKM%2BQRVl0nY97hvnkC7aHIJD%2Fw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA4VTJMZTOPRB7QNXA%2F20200901%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20200901T122324Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Signature=cba721d68fa987e804c28926604c087aa5eb104cf272dbd693cea8e053c044a8',
        type: '',
        uploaded_by: 'Mike Lam',
        uploaded_on: 'tcmg',
        uuid: '916cc22f-4bc5-4403-b9ee-4e7cfcf37f89',
      },
    ];
  }

  protected async getAttachments() {
    if (! this.project) { return; }

    const media = await new MediaModel()
      .filter({
        project: this.project.id,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (media) {
      this.attachments = media;
    }
  }

  protected close() {
    this.isCreatingAttachment = false;
    this.selectedAttachment = null;
  }

  protected editAttachment(attachment: Media) {
    this.selectedAttachment = attachment;
    this.isCreatingAttachment = true;
  }

  protected addAttachment() {
    this.isCreatingAttachment = true;
    this.selectedAttachment = null;
  }

  protected async create(keys: string[]) {
    if (! this.project || ! this.project.id || ! keys) { return; }

    for (let index = 0; index < keys.length; index += 1) {
      const key = keys[index];

      const payload: {[key: string]: string} = {
        file: `stateless-upload://s3/${key}`,
        id: this.project.id,
        model: 'project',
      };

      const fileUploadComponent = this.$refs.fileUpload as FileUpload;

      // eslint-disable-next-line no-await-in-loop
      await new MediaModel()
        .create(payload)
        .then(() => {
          fileUploadComponent.handleUploaded(index);
          this.getAttachments();
        }).catch((error: AxiosError) => {
          console.log('ERROR');
          fileUploadComponent.handleError(index);
        });
    }
  }

  protected update() {
    if (! this.selectedAttachment) {
      return;
    }

    const payload: {[key: string]: string | boolean} = {
      description: this.selectedAttachment.description ? this.selectedAttachment.description : '',
    };

    new MediaModel(this.selectedAttachment)
      .update(payload)
      .then((response: AxiosResponse) => {
        this.getAttachments();
        this.close();
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  public deleteAttachment(attachment: Media) {
    this.selectedAttachment = attachment;
    this.$store.dispatch('openDialog', this.dialogOptionsDelete);
  }

  protected get dialogOptionsDelete(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze bijlage wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          color: 'warning',
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            this.handleDeleteAttachment();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedAttachment = null;
          },
        },
      },
    };
  }

  protected handleDeleteAttachment() {
    if (! this.selectedAttachment) {
      return;
    }

    new MediaModel(this.selectedAttachment)
      .delete()
      .then((response: AxiosResponse) => {
        this.getAttachments();
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
