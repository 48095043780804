var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "tw-max-w-none tw-p-16",
      staticStyle: { height: "calc(100% - 26px)" },
      attrs: { "grid-list-lg": "" }
    },
    [
      !_vm.project
        ? _c(
            "div",
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-xs-center": "" } },
                    [
                      _vm.isLoading.page
                        ? _c("LoaderCard", {
                            attrs: {
                              flat: "",
                              type: "spinner--center",
                              minHeight: "300px"
                            }
                          })
                        : _vm._e(),
                      !_vm.isLoading.page
                        ? _c("h2", { staticClass: "fadedText" }, [
                            _vm._v("Geen project gevonden")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.project
        ? _c(
            "v-layout",
            { staticClass: "tw-h-full", attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "tw-h-full tw-p-0", attrs: { xs12: "" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "hide-slider": "" },
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.tabs, function(tab, index) {
                        return [
                          _c(
                            "v-tab",
                            {
                              key: index,
                              staticClass: "tab__filled",
                              class: { active: _vm.activeTab === tab.key },
                              attrs: { href: "#" + tab.key }
                            },
                            [_vm._v(" " + _vm._s(tab.name) + " ")]
                          )
                        ]
                      })
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      class: { "d-none": _vm.activeTab !== "petat" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "tw-h-full tw-m-0" },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "tw-p-0", attrs: { xs12: "" } },
                            [
                              !_vm.isLoading.project
                                ? _c("PeTAT", {
                                    attrs: {
                                      response: _vm.response,
                                      project: _vm.project,
                                      isLoading: _vm.isLoading.petat,
                                      defaultFilters: {
                                        statuses: ["planning"],
                                        filterApplicantSubmittedAt: false,
                                        filterDownloadedAt: false
                                      },
                                      defaultIncludes: {
                                        earthquakes: 0,
                                        gasfields: 0,
                                        gasfields_6km: 0,
                                        reports: 0
                                      },
                                      defaultZoom:
                                        _vm.project.map_settings.zoom,
                                      mapCenter: {
                                        lat:
                                          _vm.project.map_settings.center
                                            .latitude,
                                        lng:
                                          _vm.project.map_settings.center
                                            .longitude
                                      },
                                      legendaType: _vm.legendaType
                                    },
                                    on: {
                                      initialize: _vm.initializeEarthquakes,
                                      addToProject: _vm.addToProject,
                                      removeFromProject: _vm.removeFromProject,
                                      "update:legendaType": function($event) {
                                        _vm.legendaType = $event
                                      },
                                      "update:legenda-type": function($event) {
                                        _vm.legendaType = $event
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "elementPanel tw-overflow-auto",
                      class: { "d-none": _vm.activeTab !== "reports" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-flex tw-mb-24 tw-w-full tw-justify-end"
                                },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "warning",
                                        outline: true,
                                        small: "true",
                                        icon: "delete_sweep",
                                        disabled: _vm.isUnlinkingReports
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.$store.dispatch(
                                            "openDialog",
                                            _vm.unlinkAllReportsDialogOptions
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Alle dossiers ontkoppelen ")]
                                  )
                                ],
                                1
                              ),
                              _vm.activeTab === "reports"
                                ? _c("ReportDatatable", {
                                    ref: "reportDatatable",
                                    attrs: {
                                      dataTableFilters: _vm.dataTableFilters,
                                      queryStatus: "project",
                                      dataTableActions: _vm.dataTableActions
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tw-overflow-auto",
                      class: { "d-none": _vm.activeTab !== "business_rules" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "elementPanel elementPanel--spaced" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("h4", { staticClass: "elementSubTitle" }, [
                                  _vm._v("Includes")
                                ])
                              ]),
                              _vm.activeTab === "business_rules"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("PeTATFilters", {
                                        attrs: {
                                          defaultFilters:
                                            _vm.peTATFilters.includes
                                        },
                                        on: {
                                          filterChanged:
                                            _vm.filterChangedIncludes
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "elementPanel" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("h4", { staticClass: "elementSubTitle" }, [
                                  _vm._v("Excludes")
                                ])
                              ]),
                              _vm.activeTab === "business_rules"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("PeTATFilters", {
                                        attrs: {
                                          defaultFilters:
                                            _vm.peTATFilters.excludes
                                        },
                                        on: {
                                          filterChanged:
                                            _vm.filterChangedExcludes
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "elementPanel tw-overflow-auto",
                      class: { "d-none": _vm.activeTab !== "attachments" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("Attachments", {
                                attrs: { project: _vm.project }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "elementPanel tw-overflow-auto",
                      class: { "d-none": _vm.activeTab !== "stats" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "elementPanel elementPanel--spaced"
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { md6: "", lg4: "" } },
                                          [
                                            _vm.project &&
                                            _vm.project.statistics
                                              ? _c("StatisticsProgressWidget", {
                                                  attrs: {
                                                    item: {
                                                      percentage:
                                                        _vm.project.statistics
                                                          .reports.percentage
                                                          .planned || 100,
                                                      value:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .planned || 0,
                                                      target:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .all || 0
                                                    },
                                                    label: "Ingepland",
                                                    warningBoundry: 80,
                                                    okBoundry: 100
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { md6: "", lg4: "" } },
                                          [
                                            _vm.project &&
                                            _vm.project.statistics
                                              ? _c("StatisticsProgressWidget", {
                                                  attrs: {
                                                    item: {
                                                      percentage:
                                                        _vm.project.statistics
                                                          .reports.percentage
                                                          .approved || 100,
                                                      value:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .approved || 0,
                                                      target:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .all || 0
                                                    },
                                                    label: "Gelopen",
                                                    warningBoundry: 80,
                                                    okBoundry: 100
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { md6: "", lg4: "" } },
                                          [
                                            _vm.project &&
                                            _vm.project.statistics
                                              ? _c("StatisticsProgressWidget", {
                                                  attrs: {
                                                    item: {
                                                      percentage:
                                                        _vm.project.statistics
                                                          .reports.percentage
                                                          .downloaded || 100,
                                                      value:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .downloaded || 0,
                                                      target:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .all || 0
                                                    },
                                                    label:
                                                      "Verstuurd aan aanvrager",
                                                    warningBoundry: 80,
                                                    okBoundry: 100
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tw-overflow-auto",
                      class: { "d-none": _vm.activeTab !== "project" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "items-stretch": "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
                            _c(
                              "div",
                              { staticClass: "elementPanel tw-h-full" },
                              [
                                _vm.isLoading.project
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center",
                                        minHeight: "300px"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading.project && _vm.project
                                  ? [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "profileContainer",
                                          attrs: { wrap: "" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "iconContainer" },
                                            [
                                              !_vm.isEditing.project
                                                ? [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "edit-icon",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.isEditing.project = true
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "edit"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          553603034
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "project gegevens aanpassen"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          ),
                                          _vm.isEditing.project
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "iconContainer"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "cancel-icon",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.isEditing.project = false
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("cancel")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile__userName"
                                                },
                                                [
                                                  !_vm.isEditing.project
                                                    ? _c("h1", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.project.name
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _vm.isEditing.project &&
                                                  !_vm.isLoading.project
                                                    ? [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs12: "",
                                                              "mb-3": ""
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Naam",
                                                                "hide-details": true
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.project
                                                                    .name,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.project,
                                                                    "name",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "project.name"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              attrs: {
                                                                label:
                                                                  "Project type",
                                                                "item-value":
                                                                  "value",
                                                                "item-text":
                                                                  "name",
                                                                items:
                                                                  _vm.projectTypes,
                                                                "hide-details":
                                                                  ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.project
                                                                    .type,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.project,
                                                                    "type",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "project.type"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "MapAreaFilter",
                                                              {
                                                                attrs: {
                                                                  area:
                                                                    _vm.project
                                                                      .map_area
                                                                },
                                                                on: {
                                                                  selectedArea:
                                                                    _vm.handleSelectedArea
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c("v-textarea", {
                                                              attrs: {
                                                                label:
                                                                  "Instructie planning",
                                                                "hide-details": true
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.project
                                                                    .instruction_planning,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.project,
                                                                    "instruction_planning",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "project.instruction_planning"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs12: "",
                                                              "mb-3": ""
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label:
                                                                  "Map Settings: Latitude center",
                                                                "hide-details": true
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.project
                                                                    .map_settings
                                                                    .center
                                                                    .latitude,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.project
                                                                      .map_settings
                                                                      .center,
                                                                    "latitude",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "project.map_settings.center.latitude"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs12: "",
                                                              "mb-3": ""
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label:
                                                                  "Map Settings: Longitude center",
                                                                "hide-details": true
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.project
                                                                    .map_settings
                                                                    .center
                                                                    .longitude,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.project
                                                                      .map_settings
                                                                      .center,
                                                                    "longitude",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "project.map_settings.center.longitude"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs12: "",
                                                              "mb-3": ""
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label:
                                                                  "Map Settings: default zoom",
                                                                "hide-details": true
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.project
                                                                    .map_settings
                                                                    .zoom,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.project
                                                                      .map_settings,
                                                                    "zoom",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "project.map_settings.zoom"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs12: "",
                                                              "mt-2": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "MiButton",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  small: "true",
                                                                  outline:
                                                                    "true"
                                                                },
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.updateProject(
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Opslaan "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      !_vm.isEditing.project
                                        ? _c(
                                            "v-layout",
                                            {
                                              staticClass:
                                                "pt-2 profileContainer",
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { sm3: "" } },
                                                [
                                                  _c(
                                                    "ElementPanelItem",
                                                    {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _vm._v(
                                                                  "Project type"
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        2897175670
                                                      )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "parseProjectType"
                                                            )(
                                                              !_vm.project.type
                                                                ? "-"
                                                                : _vm.project
                                                                    .type
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { sm3: "" } },
                                                [
                                                  _c(
                                                    "ElementPanelItem",
                                                    {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _vm._v("Gebied")
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        1965721523
                                                      )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.project
                                                              .map_area &&
                                                              _vm.project
                                                                .map_area.name
                                                              ? _vm.project
                                                                  .map_area.name
                                                              : "-"
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { sm3: "" } },
                                                [
                                                  _c(
                                                    "ElementPanelItem",
                                                    {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _vm._v(
                                                                  "Aangemaakt"
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        3499335269
                                                      )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormatTime"
                                                            )(
                                                              !_vm.project
                                                                .created_at
                                                                ? "-"
                                                                : _vm.project
                                                                    .created_at
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { sm3: "" } },
                                                [
                                                  _c(
                                                    "ElementPanelItem",
                                                    {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _vm._v(
                                                                  "Instructie planning"
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        2996315834
                                                      )
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.project
                                                                .instruction_planning
                                                                ? _vm.project
                                                                    .instruction_planning
                                                                : "-"
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _c("v-flex", { attrs: { xs12: "", md4: "" } }, [
                            _c(
                              "div",
                              { staticClass: "elementPanel tw-h-full" },
                              [
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "profileContainer",
                                      class: {
                                        editing: _vm.isEditing.organization
                                      },
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      !_vm.isEditing.organization &&
                                      !_vm.isLoading.organization
                                        ? _c(
                                            "div",
                                            { staticClass: "iconContainer" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "iconContainer__edit"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "edit-icon",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.isEditing.organization = true
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "edit"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        2030121972
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Bureau aanpassen"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isEditing.organization &&
                                      !_vm.isLoading.organization
                                        ? _c(
                                            "div",
                                            { staticClass: "iconContainer" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "cancel-icon",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isEditing.organization = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("cancel")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "span",
                                          { staticClass: "profile__userName" },
                                          [_vm._v("Bureau")]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                _vm.isLoading.organization
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center",
                                        minHeight: "300px"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading.organization
                                  ? [
                                      !_vm.isEditing.organization
                                        ? [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass:
                                                  "pt-2 item__list odd",
                                                attrs: { wrap: "" }
                                              },
                                              [
                                                _vm.selectedOrganization
                                                  ? [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "list__item",
                                                          attrs: { xs12: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .selectedOrganization
                                                                        .name
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "mt-2 fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Geen bureau geselecteerd"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                              ],
                                              2
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.isEditing.organization
                                        ? [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass: "profileContainer",
                                                attrs: {
                                                  row: "",
                                                  wrap: "",
                                                  "align-center": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      "mb-3": ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items:
                                                          _vm.organizations,
                                                        label:
                                                          "Expertisebureau",
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        "hide-details": "",
                                                        "return-object": ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedOrganization,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectedOrganization = $$v
                                                        },
                                                        expression:
                                                          "selectedOrganization"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "MiButton",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          small: "true",
                                                          outline: "true"
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.updateOrganization(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Opslaan ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _c("v-flex", { attrs: { xs12: "", md4: "" } }, [
                            _c(
                              "div",
                              { staticClass: "elementPanel tw-h-full" },
                              [
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "profileContainer",
                                      class: { editing: _vm.isEditing.experts },
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      !_vm.isEditing.experts &&
                                      !_vm.isLoading.organization &&
                                      _vm.selectedOrganization
                                        ? _c(
                                            "div",
                                            { staticClass: "iconContainer" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "iconContainer__edit"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "edit-icon",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.isEditing.experts = true
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "edit"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        3301105234
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Deskundigen aanpassen"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isEditing.experts &&
                                      !_vm.isLoading.experts
                                        ? _c(
                                            "div",
                                            { staticClass: "iconContainer" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "cancel-icon",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isEditing.experts = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("cancel")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "span",
                                          { staticClass: "profile__userName" },
                                          [_vm._v("Deskundigen")]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                _vm.isLoading.experts
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center",
                                        minHeight: "300px"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading.experts
                                  ? [
                                      !_vm.isEditing.experts
                                        ? [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass:
                                                  "pt-2 item__list odd",
                                                attrs: { wrap: "" }
                                              },
                                              [
                                                _vm.selectedExperts &&
                                                _vm.selectedExperts.length
                                                  ? [
                                                      _vm._l(
                                                        _vm.selectedExperts,
                                                        function(
                                                          expert,
                                                          expertIndex
                                                        ) {
                                                          return [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                key: expertIndex,
                                                                staticClass:
                                                                  "list__item",
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-layout",
                                                                  {
                                                                    attrs: {
                                                                      wrap: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs12:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            expert.name
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      )
                                                    ]
                                                  : [
                                                      !_vm.selectedOrganization
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mt-2 fadedText"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Selecteer eerst een Bureau"
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mt-2 fadedText"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Geen deskundigen geselecteerd"
                                                              )
                                                            ]
                                                          )
                                                    ]
                                              ],
                                              2
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.isEditing.experts
                                        ? [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass: "profileContainer",
                                                attrs: {
                                                  row: "",
                                                  wrap: "",
                                                  "align-center": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      "mb-3": ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass:
                                                        "with-border",
                                                      attrs: {
                                                        items: _vm.experts,
                                                        label:
                                                          "Selecteer deskundigen",
                                                        "item-text": "name",
                                                        "item-value": "uuid",
                                                        "hide-details": "",
                                                        "search-input":
                                                          _vm.expertSearch,
                                                        "return-object": "",
                                                        "hide-no-data": "",
                                                        multiple: "",
                                                        "no-filter": ""
                                                      },
                                                      on: {
                                                        keyup:
                                                          _vm.debouncedSearchExperts,
                                                        "update:searchInput": function(
                                                          $event
                                                        ) {
                                                          _vm.expertSearch = $event
                                                        },
                                                        "update:search-input": function(
                                                          $event
                                                        ) {
                                                          _vm.expertSearch = $event
                                                        }
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "selection",
                                                            fn: function(ref) {
                                                              var index =
                                                                ref.index
                                                              return [
                                                                index === 0
                                                                  ? [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Deskundige"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "tw-ml-4 grey--text caption"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "(" +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .selectedExperts
                                                                                  .length
                                                                              ) +
                                                                              ")"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        4192153162
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.selectedExperts,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectedExperts = $$v
                                                        },
                                                        expression:
                                                          "selectedExperts"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "MiButton",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          small: "true",
                                                          outline: "true"
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.updateExperts(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Opslaan ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _c("v-flex", { attrs: { xs12: "", md4: "" } }, [
                            _c(
                              "div",
                              { staticClass: "elementPanel tw-h-full" },
                              [
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "profileContainer",
                                      class: {
                                        editing: _vm.isEditing.planners
                                      },
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      !_vm.isEditing.planners &&
                                      !_vm.isLoading.organization
                                        ? _c(
                                            "div",
                                            { staticClass: "iconContainer" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "iconContainer__edit"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "edit-icon",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.isEditing.planners = true
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "edit"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        3051842038
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Planners aanpassen"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isEditing.planners &&
                                      !_vm.isLoading.planners
                                        ? _c(
                                            "div",
                                            { staticClass: "iconContainer" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "cancel-icon",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isEditing.planners = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("cancel")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "span",
                                          { staticClass: "profile__userName" },
                                          [_vm._v("Planners")]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                _vm.isLoading.planners
                                  ? _c("LoaderCard", {
                                      attrs: {
                                        flat: "",
                                        type: "spinner--center",
                                        minHeight: "300px"
                                      }
                                    })
                                  : _vm._e(),
                                !_vm.isLoading.planners
                                  ? [
                                      !_vm.isEditing.planners
                                        ? [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass:
                                                  "pt-2 item__list odd",
                                                attrs: { wrap: "" }
                                              },
                                              [
                                                _vm.selectedPlanners &&
                                                _vm.selectedPlanners.length
                                                  ? [
                                                      _vm._l(
                                                        _vm.selectedPlanners,
                                                        function(
                                                          planner,
                                                          plannerIndex
                                                        ) {
                                                          return [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                key: plannerIndex,
                                                                staticClass:
                                                                  "list__item",
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-layout",
                                                                  {
                                                                    attrs: {
                                                                      wrap: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs12:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            planner.name
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      )
                                                    ]
                                                  : [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "mt-2 fadedText"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Geen planner geselecteerd"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                              ],
                                              2
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.isEditing.planners
                                        ? [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass: "profileContainer",
                                                attrs: {
                                                  row: "",
                                                  wrap: "",
                                                  "align-center": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      "mb-3": ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass:
                                                        "with-border",
                                                      attrs: {
                                                        items: _vm.planners,
                                                        label:
                                                          "Selecteer planners",
                                                        "item-text": "name",
                                                        "item-value": "uuid",
                                                        "hide-details": "",
                                                        "search-input":
                                                          _vm.plannerSearch,
                                                        "return-object": "",
                                                        "hide-no-data": "",
                                                        multiple: "",
                                                        "no-filter": ""
                                                      },
                                                      on: {
                                                        keyup:
                                                          _vm.debouncedSearchPlanners,
                                                        "update:searchInput": function(
                                                          $event
                                                        ) {
                                                          _vm.plannerSearch = $event
                                                        },
                                                        "update:search-input": function(
                                                          $event
                                                        ) {
                                                          _vm.plannerSearch = $event
                                                        }
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "selection",
                                                            fn: function(ref) {
                                                              var index =
                                                                ref.index
                                                              return [
                                                                index === 0
                                                                  ? [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Planner"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "tw-ml-4 grey--text caption"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "(" +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .selectedPlanners
                                                                                  .length
                                                                              ) +
                                                                              ")"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        894682505
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.selectedPlanners,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectedPlanners = $$v
                                                        },
                                                        expression:
                                                          "selectedPlanners"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "MiButton",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          small: "true",
                                                          outline: "true"
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.updatePlanners(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Opslaan ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }