var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$slots["label"]
      ? _c("div", { staticClass: "label" }, [_vm._t("label")], 2)
      : _vm._e(),
    _c("div", { staticClass: "value disabled" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }