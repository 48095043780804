import Attachments from '@/views/Projects/Attachments/Attachments.vue';
import { Component, Vue } from 'vue-property-decorator';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { debounce, DebouncedFunc, cloneDeep, merge } from 'lodash';
import { User } from '@/models/User';
import { Project as ProjectModel, projectTypes, ProjectType, BusinessRules, BusinessRulesFilters } from '@/models/Project';
import { Organization } from '@/models/Organization';
import PeTAT from '@/components/PeTAT/PeTAT.vue';
import { Token } from '@/support/Token';
import { identifyHostname } from '@/support/Client';
import { MapArea } from '@/models/MapArea';
import PeTATFilters from '@/components/PeTATFilters/PeTATFilters.vue';
import { ReportPeTATFilters } from '@/components/PeTATFilters/PeTATFilters';
import { Report } from '@/models/Report';
import ElementPanelItem from '@/components/ElementPanelItem.vue';
import DataTable, { TableActions } from '@/components/data-table/DataTable';
import { Rpc } from '@/models/Rpc';
import { Options } from '@/components/mi-dialog/MiDialog';
import { addClass, removeClass } from '@/support/Html';
import { PetatLegendaTypes } from '@/components/PeTAT/PeTAT';

@Component<ProjectManager>({
  filters: {
    parseProjectType: (type: string) => {
      const foundType = projectTypes.find((projectType: ProjectType) => projectType.value === type);

      return foundType ? foundType.name : '-';
    },
  },
  components: {
    PeTAT,
    PeTATFilters,
    ElementPanelItem,
    Attachments,
  },
})
export default class ProjectManager extends Vue {
  public $pageTitle = 'Project';

  protected project: ProjectModel | null = null;

  protected projectTypes: ProjectType[] = projectTypes;

  protected selectedMapArea: MapArea | null = null;

  protected peTATFilters: BusinessRulesPeTATFilters = {
    includes: {},
    excludes: {},
  };

  protected business_rules: BusinessRules = {
    includes: {},
    excludes: {},
  }

  protected isLoading = {
    page: true,
    project: false,
    organization: false,
    experts: false,
    planners: false,
    petat: false,
  };

  protected isEditing = {
    page: false,
    project: false,
    organization: false,
    experts: false,
    planners: false,
  };

  protected isUnlinkingReports = false;

  protected defaultMapSettings = {
    center: {
      latitude: 53.250748,
      longitude: 6.513490,
    },
    zoom: 10,
  };

  // PETAT TOOL
  protected response: any | null = null;

  protected legendaType = PetatLegendaTypes.PROJECT;

  public oAuthServer: string = identifyHostname() || '';

  // TABS
  protected activeTab = 'petat';

  // Organizations
  protected organizations: Organization[] = [];

  protected selectedOrganization: Organization | null = null;

  // search experts
  protected debouncedSearchExperts: DebouncedFunc<() => void> = debounce(this.handleSearchExperts, 300);

  protected expertSearch = '';

  protected experts: User[] = [];

  protected selectedExperts: User[] = [];

  // search planners
  protected debouncedSearchPlanners: DebouncedFunc<() => void> = debounce(this.handleSearchPlanners, 300);

  protected plannerSearch = '';

  protected planners: User[] = [];

  protected selectedPlanners: User[] = [];

  protected mounted(): void {
    this.initialize();
  }

  protected beforeDestroy() {
    const pageContainer = document.querySelectorAll('.pageContainer')[0];
    removeClass(pageContainer, 'pageContainer--fullwidth');
  }

  protected async initialize(): Promise<void> {
    this.isLoading.page = true;
    const pageContainer = document.querySelectorAll('.pageContainer')[0];
    addClass(pageContainer, 'pageContainer--fullwidth');
    await this.fetchProject();
    await this.getOrganizations();
    this.initBreadcrumb();
    this.handleSearchExperts();
    this.handleSearchPlanners();
    this.isLoading.page = false;
  }

  protected initBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle projecten', path: '/projecten' },
          { name: this.project ? this.project.name : '', path: `/projecten/${this.project?.id}` },
          { name: 'Beheren' },
        ],
      });
  }

  protected async fetchProject(): Promise<void> {
    if (! this.$route.params.id) {
      return;
    }

    this.project = null;

    this.project = await new ProjectModel()
      .include([
        'statistics',
        'organization',
        'map_area',
        'business_rules',
      ])
      .find(this.$route.params.id);

    if (this.project?.organization) {
      this.selectedOrganization = this.project.organization;
    }

    if (this.project?.business_rules) {
      this.business_rules = this.project.business_rules;
      this.businessRulesFiltersToPeTATFilters();
    }

    if (this.project) {
      this.project.map_settings = ! this.project.map_settings
        ? cloneDeep(this.defaultMapSettings) // if the object was not set at all, use the defaults
        : merge(this.defaultMapSettings, this.project.map_settings); // if the object was set, do a deepmerge from the settings to the defaul settings so we don't miss out on anything
    }
  }

  protected async getOrganizations(): Promise<void> {
    await new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;
      });
  }

  protected handleSearchExperts(): void {
    new User()
      .filter('search', this.expertSearch)
      .limit(10)
      .all()
      .then((users: User[]) => {
        this.experts = [...this.selectedExperts, ...users];
      });
  }

  protected handleSearchPlanners(): void {
    new User()
      .filter('search', this.plannerSearch)
      .limit(5)
      .all()
      .then((users: User[]) => {
        this.planners = [...this.selectedPlanners, ...users];
      });
  }

  protected async updateProject(): Promise<void> {
    if (! this.project) { return; }
    this.isLoading.project = true;

    const project = await this.project
      .include([
        'statistics',
        'organization',
        'map_area',
      ])
      .update({
        name: this.project.name,
        instruction_planning: this.project.instruction_planning,
        type: this.project.type,
        map_area: this.selectedMapArea?.id,
        map_settings: this.project.map_settings,
      });

    this.project = project;

    this.isLoading.project = false;
    this.isEditing.project = false;
  }

  protected async updateBusinessRules(): Promise<void> {
    if (! this.project) { return; }
    const project = await this.project
      .include([
        'reports',
        'users',
        'organization',
      ])
      .update({
        name: this.project.name,
        business_rules: this.business_rules,
      });

    this.project = project;
  }

  protected async updateOrganization(): Promise<void> {
    if (! this.project) { return; }
    this.isLoading.organization = true;

    const project = await this.project
      .include([
        'statistics',
        'organization',
        'map_area',
      ])
      .update({
        organization: this.selectedOrganization?.id,
      });

    this.project.name = project.name;

    this.isLoading.organization = false;
    this.isEditing.organization = false;
  }

  protected async updateExperts(): Promise<void> {
    if (! this.project) { return; }
    this.isLoading.experts = true;

    const project = await this.project
      .update({
        name: this.project.name,
      });

    this.project.name = project.name;

    this.isLoading.experts = false;
    this.isEditing.experts = false;
  }

  protected async updatePlanners(): Promise<void> {
    if (! this.project) { return; }
    this.isLoading.planners = true;

    const project = await this.project
      .update({
        name: this.project.name,
      });

    this.project.name = project.name;

    this.isLoading.planners = false;
    this.isEditing.planners = false;
  }

  protected async addToProject(reports: Report[], force: boolean): Promise<void> {
    const reportIds = reports.map((report: any) => report.id);
    this.project?.addReports(reportIds, force)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
    this.refreshDatatable();
  }

  protected async removeFromProject(reports: any): Promise<void> {
    const reportIds = reports.map((report: any) => report.id);
    this.project?.removeReports(reportIds)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
    this.refreshDatatable();
  }

  protected get successDialogOptions(): Options {
    return {
      title: 'Alle dossiers zijn succesvol geontkoppeld',
      text: '',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get unlinkAllReportsDialogOptions(): Options {
    return {
      title: 'Weet je zeker dat je alle dossiers van dit project wilt ontkoppelen?',
      text: '',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.unlinkAllReports();
          },
        },
      },
    };
  }

  protected async unlinkAllReports(): Promise<void> {
    if (! this.project) {
      return;
    }

    this.isUnlinkingReports = true;

    const payload = {
      signature: 'project:unlink-all-reports',
      body: {
        project: this.project.id,
      },
    };

    await new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.isUnlinkingReports = false;
        this.refreshDatatable();
        this.$store.dispatch('openDialog', this.successDialogOptions);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected handleSelectedArea(area: MapArea): void {
    this.selectedMapArea = area;
  }

  protected get dataTableFilters(): Record<string, unknown> {
    return {
      project: this.project ? this.project.id : null,
    };
  }

  protected refreshDatatable(): void {
    if (this.$refs.reportDatatable) {
      (this.$refs.reportDatatable as DataTable).refresh();
    }
  }

  // PETAT TOOL
  protected async initializeEarthquakes(payload: string): Promise<void> {
    this.isLoading.petat = true;

    const client = this.getAxiosClient();

    await client
      .get(`/v1/tools/effectgebied?${payload}`)
      .then((response: AxiosResponse) => {
        this.response = response;
        this.isLoading.petat = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getAxiosClient(): AxiosInstance {
    const headers = {};
    if (Token.get('access')) {
      (headers as any).Authorization = `Bearer ${Token.get('access')}`;
    }

    const axiosClient = axios.create({
      baseURL: this.oAuthServer,
      headers,
    });
    return axiosClient;
  }

  // Business rules
  protected filterChangedIncludes(filters: ReportPeTATFilters): void {
    this.business_rules.includes = this.businessRulesFilters(filters);
    this.updateBusinessRules();
  }

  protected filterChangedExcludes(filters: ReportPeTATFilters): void {
    this.business_rules.excludes = this.businessRulesFilters(filters);
    this.updateBusinessRules();
  }

  protected businessRulesFilters(filters: ReportPeTATFilters): ReportPeTATFilters {
    return {
      status: filters.statuses,
      organizations: filters.organizations,
      report_handler_users: filters.report_handlers,
      case_mediator_users: filters.mediators,
      report_types: filters.types,
      tags: filters.tags,
      skills: filters.skills,
      departments: filters.departments,
      deadline_expired: filters.overdue,
      kpi_contract_expired: filters.overdue_kpi,
      prio: filters.is_prio,
      special: filters.is_special,
      submitted_active: filters.filterApplicantSubmittedAt,
      submitted_from: filters.applicant_submitted_at?.from || '',
      submitted_to: filters.applicant_submitted_at?.to || '',
      downloaded_active: filters.filterDownloadedAt,
      downloaded_from: filters.downloaded_at?.from || '',
      downloaded_to: filters.downloaded_at?.from || '',
      payout_total_active: filters.filterPayoutTotal,
      payout_total_from: filters.payout_total?.from,
      payout_total_to: filters.payout_total?.to,
    };
  }

  protected businessRulesFiltersToPeTATFilters(): void {
    this.peTATFilters.excludes = this.reportPeTATFilters(this.business_rules.excludes);
    this.peTATFilters.includes = this.reportPeTATFilters(this.business_rules.includes);
  }

  protected reportPeTATFilters(filters: BusinessRulesFilters): ReportPeTATFilters {
    return {
      report_handlers: filters.report_handler_users,
      statuses: filters.status,
      organizations: filters.organizations,
      mediators: filters.case_mediator_users,
      types: filters.report_types,
      tags: filters.tags,
      skills: filters.skills,
      departments: filters.departments,
      applicant_submitted_at: {
        from: filters.submitted_from,
        to: filters.submitted_to,
      },
      downloaded_at: {
        from: filters.downloaded_from,
        to: filters.downloaded_to,
      },
      payout_total: {
        from: filters.payout_total_from,
        to: filters.payout_total_to,
      },
      filterApplicantSubmittedAt: filters.submitted_active,
      filterDownloadedAt: filters.downloaded_active,
      filterPayoutTotal: filters.payout_total_active,
      overdue: filters.payout_total_active,
      overdue_kpi: filters.deadline_expired,
      is_prio: filters.prio,
      is_special: filters.special,
    };
  }

  protected get dataTableActions(): TableActions[] {
    return [
      {
        type: 'delete',
        label: 'delete',
        icon: 'delete',
        action: (dataTable: DataTable, report: Report) => {
          if (report) {
            this.project?.removeReports([report.uuid])
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
            this.refreshDatatable();
          }
        },
        tooltip: () => 'Verwijder dit rapport',
      },
    ];
  }

  protected get tabs(): TabItem[] {
    return [
      {
        name: 'PeTAT',
        key: 'petat',
      },
      {
        name: 'Dossiers',
        key: 'reports',
      },
      {
        name: 'Business rules',
        key: 'business_rules',
      },
      {
        name: 'Bijlagen',
        key: 'attachments',
      },
      {
        name: 'Project informatie',
        key: 'project',
      },
    ];
  }
}

interface BusinessRulesPeTATFilters {
  includes: ReportPeTATFilters;
  excludes: ReportPeTATFilters;
}

interface TabItem {
  name?: string,
  key?: string,
}
