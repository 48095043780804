










import { Component, Vue } from 'vue-property-decorator';

@Component<ElementPanelItem>({})
export default class ElementPanelItem extends Vue {

}
